// SectionLoading.tsx
import React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 100px; /* Increased the width for better spacing */
  height: 100px; /* Increased the height for better spacing */
  animation: spin 2s linear infinite;
  display: flex; /* Make the spinner a flex container */
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SectionLoading: React.FC = () => {
  return (
    <LoadingWrapper>
      <LoadingSpinner></LoadingSpinner>
    </LoadingWrapper>
  );
};

export default SectionLoading;
