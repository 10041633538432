import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ScrollWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  touch-action: pan-x;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface HorizontalScrollWrapperProps {
  children: React.ReactNode;
  itemsPerPage?: number;
}

const HorizontalScrollWrapper: React.FC<HorizontalScrollWrapperProps> = ({
  children,
  itemsPerPage,
}) => {
  const [calculatedItemsPerPage, setCalculatedItemsPerPage] = useState<
    number | undefined
  >(itemsPerPage);

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const containerWidth =
        document.getElementById('scroll-wrapper')?.clientWidth ||
        window.innerWidth;
      if (containerWidth <= 320) {
        return 1;
      } else if (containerWidth <= 768) {
        return 3;
      } else {
        return 5;
      }
    };

    const handleResize = () => {
      setCalculatedItemsPerPage(itemsPerPage || calculateItemsPerPage());
    };

    setCalculatedItemsPerPage(itemsPerPage || calculateItemsPerPage());
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [itemsPerPage]);

  return (
    <ScrollWrapper id="scroll-wrapper">
      {React.Children.map(children, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </ScrollWrapper>
  );
};

export default HorizontalScrollWrapper;
