//Section
import React from 'react';
import styled from 'styled-components';

import {
  HEADER_HEIGHT,
  MEDIA_BREAKPOINTS,
  SEPARATOR_HEIGHT,
} from '@/constants';
import { useResponsiveBackgroundImage } from '@/hooks/useResponsiveBackgroundImage'; // Ensure the path is correctly updated

interface SectionBaseProps {
  $backgroundImageUrl: string; // Use transient prop
}

const SectionBase = styled.section<SectionBaseProps>`
  height: calc(100vh - ${SEPARATOR_HEIGHT.smallScreen * 2}px);
  color: ${({ theme }) => theme.textColor};
  background-image: url(${props => props.$backgroundImageUrl});
  background-position: center center;

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    height: calc(
      100vh - ${HEADER_HEIGHT + SEPARATOR_HEIGHT.mediumScreen * 2}px
    );
  }
`;

const SectionContent = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

interface SectionProps {
  children?: React.ReactNode;
  sectionIndex: number; // Changed to sectionIndex to match the hook's requirement
}

const Section = React.forwardRef<HTMLElement, SectionProps>(
  ({ children, sectionIndex }, ref) => {
    const backgroundImageUrl = useResponsiveBackgroundImage(sectionIndex);

    return (
      <SectionBase ref={ref} $backgroundImageUrl={backgroundImageUrl}>
        <SectionContent>{children}</SectionContent>
      </SectionBase>
    );
  },
);

export default Section;
