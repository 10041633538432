export interface ElementSizes {
  circleImage: string;
  horizontalImageWidth: string;
  horizontalImageHeight: string;
  smallImageWidth: string;
  smallImageHeight: string;
  largeImageWidth: string;
  largeImageHeight: string;
  iconWidth: string;
  iconHeight: string;
  logoWidth: string;
  logoHeight: string;
  videoWidth: string;
  videoHeight: string;
  containerWidth: string;
  containerHeight: string;
  titleFontSize: string;
  descriptionLargeFontSize: string;
  descriptionSmallFontSize: string;
  menuButtonWidth: string;
  menuButtonHeight: string;
  inputWidth: string;
  inputHight: string;
  mapSize: string;
  formButtonPadding: string;
  cardContainerWidth: string;
  cardContainerHeight: string;
}

interface Fonts {
  rubikBold: string;
  rubikBoldItalic: string;
  rubikExtraBold: string;
  rubikRegular: string;
  rubikSemiBold: string;
  rubikSemiBoldItalic: string;
}

interface CommonTheme {
  sizes: {
    small: ElementSizes;
    medium: ElementSizes;
    large: ElementSizes;
    fonts: Fonts;
  };
}

export interface Theme extends CommonTheme {
  gradientColors: string[];
  buttonBackgroundColor: string;
  textColor: string;
  titleColor: string;
  descriptionColor: string;
  iconColor: string;
  MenuItemSelectedColor: string;
  menuColor: string;
  borderColor: string;
  shadowDarkColor: string;
  shadowLightColor: string;
  circleTextColor: string;
}

const commonTheme: CommonTheme = {
  sizes: {
    small: {
      circleImage: '180px',
      horizontalImageWidth: '400px',
      horizontalImageHeight: '300px',
      smallImageWidth: '60px',
      smallImageHeight: '60px',
      largeImageWidth: '70vw',
      largeImageHeight: '70vh',
      iconWidth: '30px',
      iconHeight: '30px',
      logoWidth: '200px',
      logoHeight: '30px',
      videoWidth: '100%',
      videoHeight: '100%',
      mapSize: '200px',
      containerWidth: '90%',
      containerHeight: '100%',
      titleFontSize: '27px',
      descriptionLargeFontSize: '19px',
      descriptionSmallFontSize: '17px',
      menuButtonWidth: '100px',
      menuButtonHeight: '40px',
      inputWidth: '70%',
      inputHight: '8px',
      formButtonPadding: '5px 20px',
      cardContainerWidth: '300px',
      cardContainerHeight: '600px',
    },
    medium: {
      circleImage: '400px',
      horizontalImageWidth: '600px',
      horizontalImageHeight: '350px',
      smallImageWidth: '85px',
      smallImageHeight: '85px',
      largeImageWidth: '35vw',
      largeImageHeight: '65vh',
      iconWidth: '30px',
      iconHeight: '30px',
      logoWidth: '200px',
      logoHeight: '30px',
      videoWidth: '80vw',
      videoHeight: '80vh',
      mapSize: '200px',
      containerWidth: '85%',
      containerHeight: '100%',
      titleFontSize: '40px',
      descriptionLargeFontSize: '25px',
      descriptionSmallFontSize: '20px',
      menuButtonWidth: '80px',
      menuButtonHeight: '40px',
      inputWidth: '70%',
      inputHight: '10px',
      formButtonPadding: '10px 30px',
      cardContainerWidth: '500px',
      cardContainerHeight: '500px',
    },
    large: {
      circleImage: '600px',
      horizontalImageWidth: '700px',
      horizontalImageHeight: '400px',
      smallImageWidth: '110px',
      smallImageHeight: '110px',
      largeImageWidth: '25vw',
      largeImageHeight: '60vh',
      iconWidth: '40px',
      iconHeight: '40px',
      logoWidth: '200px',
      logoHeight: '30px',
      videoWidth: '60vw',
      videoHeight: '60vh',
      mapSize: '200px',
      containerWidth: '65%',
      containerHeight: '100%',
      titleFontSize: '50px',
      descriptionLargeFontSize: '30px',
      descriptionSmallFontSize: '22px',
      menuButtonWidth: '140px',
      menuButtonHeight: '40px',
      inputWidth: '70%',
      inputHight: '12px',
      formButtonPadding: '10px 40px',
      cardContainerWidth: '600px',
      cardContainerHeight: '600px',
    },
    fonts: {
      rubikBold: 'Rubik-Bold',
      rubikBoldItalic: 'Rubik-BoldItalic',
      rubikExtraBold: 'Rubik-ExtraBold',
      rubikRegular: 'Rubik-Regular',
      rubikSemiBold: 'Rubik-SemiBold',
      rubikSemiBoldItalic: 'Rubik-SemiBoldItalic',
    },
  },
};
export const darkTheme: Theme = {
  ...commonTheme,
  gradientColors: ['#2979FF', '#FF1744'],
  buttonBackgroundColor: '#000000',
  textColor: '#ffffff',
  titleColor: '#b341c4',
  descriptionColor: '#fafafa',
  iconColor: '#000000',
  MenuItemSelectedColor: '#8943a9',
  menuColor: '#212121',
  borderColor: '#f8f8f8',
  shadowDarkColor: '#000000',
  shadowLightColor: '#f8f8f8',
  circleTextColor: '#b341c4',
};

export const lightTheme: Theme = {
  ...commonTheme,
  gradientColors: ['#FF1744', '#2979FF'],
  buttonBackgroundColor: '#ffffff',
  textColor: '#333333',
  titleColor: '#cb62dc',
  descriptionColor: '#000000',
  iconColor: '#000000',
  MenuItemSelectedColor: '#a24fc8',
  menuColor: '#E0E0E0',
  borderColor: '#000000',
  shadowDarkColor: '#f8f8f8',
  shadowLightColor: '#000000',
  circleTextColor: '#b341c4',
};
