import React from 'react';
import styled from 'styled-components';
import preventDefaultEvents from '../hoc/preventDefaultEvents';
import { MEDIA_BREAKPOINTS } from '@/constants';

const StyledSmallImage = styled.img`
  width: ${({ theme }) => theme.sizes.small.smallImageWidth};
  height: ${({ theme }) => theme.sizes.small.smallImageHeight};
  transition:
    width 0.3s ease,
    height 0.3s ease;

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.smallImageWidth};
    height: ${({ theme }) => theme.sizes.medium.smallImageHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.smallImageWidth};
    height: ${({ theme }) => theme.sizes.large.smallImageHeight};
  }
`;

interface SmallImageProps {
  src: string;
  alt?: string;
}

const SmallImage: React.FC<SmallImageProps> = props => (
  <StyledSmallImage {...props} />
);

export default preventDefaultEvents(SmallImage);
