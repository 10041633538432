import React from 'react';
import styled from 'styled-components';
import { IoMenu } from 'react-icons/io5';

import { MEDIA_BREAKPOINTS, SECTIONS } from '@/constants';

import { ToggleThemeButton, SocialMediaMenu } from '@/components';
import { MenuButton } from '@/components/StyledComponents';

const CollapsibleMenu = styled.div<{ $isActive: boolean }>`
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transform: translateY(${({ $isActive }) => ($isActive ? '0' : '-10px')});
  transition:
    visibility 0s,
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50px;
  right: 15px;
  background-color: ${({ theme }) => theme.menuColor};
  width: 100px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 1100;
  padding: 0px 0;
`;

const IoMenuIcon = styled(IoMenu)`
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1100;
  color: ${({ theme }) => theme.iconColor}; // Ensuring high contrast color
  width: ${({ theme }) => theme.sizes.small.iconWidth};
  height: ${({ theme }) => theme.sizes.small.iconHeight};
  background: linear-gradient(
    100deg,
    ${({ theme }) => theme.gradientColors[0]},
    ${({ theme }) => theme.gradientColors[1]}
  );
  border-radius: 50%; // Keeps the background circle perfect
  border: 2px solid ${({ theme }) => theme.backgroundColor}; // Adds a border that matches the background color for more separation
`;

// Since hover effects are not applicable for mobile, you might consider adding a subtle scale transformation when the icon is tapped. This can be handled in the component's logic using onTouchStart and onTouchEnd events for a slight increase in size upon touch, giving a responsive feel to the user. However, remember to test such interactions for usability and ensure they enhance the user experience without causing any unintended behavior.

const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MediaQuery = styled.div`
  @media (max-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    display: block;
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    display: none;
  }
`;

const SocialsContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0px;
  z-index: 1100;
  flex-direction: column;
`;

interface MenuMobileProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  selectedSection: string;
  scrollToSection: (sectionName: string) => void;
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  isMenuOpen,
  toggleMenu,
  selectedSection,
  scrollToSection,
}) => {
  const handleMenuItemClick = (sectionName: string) => {
    scrollToSection(sectionName);
    toggleMenu();
  };

  return (
    <MediaQuery>
      <IoMenuIcon size={60} onClick={toggleMenu} />
      <SocialsContainer>
        <SocialMediaMenu orientation="vertical" />
      </SocialsContainer>
      <CollapsibleMenu $isActive={isMenuOpen}>
        {SECTIONS.map(section => (
          <MenuButton
            key={section}
            $isSelected={selectedSection === section}
            onClick={() => handleMenuItemClick(section)}
          >
            {section}
          </MenuButton>
        ))}
        <MenuButton>
          <CenteredButtonWrapper>
            <ToggleThemeButton onToggle={toggleMenu} />
          </CenteredButtonWrapper>
        </MenuButton>
      </CollapsibleMenu>
    </MediaQuery>
  );
};

export default MenuMobile;
