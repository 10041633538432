import React from 'react';
import styled from 'styled-components';

// Styled component for the centered error message
const CenteredErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #333;
  font-size: 20px;
`;

// Error component
const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return <CenteredErrorMessage>{message}</CenteredErrorMessage>;
};

export default ErrorMessage;
