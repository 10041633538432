import React, { useState } from 'react';
import styled from 'styled-components';
import { FaMoon } from 'react-icons/fa';
import { FiSun } from 'react-icons/fi';

import { MEDIA_BREAKPOINTS } from '@/constants';
import { useTheme } from '@/theme/ThemeProvider';
import { darkTheme } from '@/theme/themes';

import { Icon } from '../StyledComponents';

const StyledToggleThemeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.menuColor};
  color: ${({ theme }) => theme.textColor};
  padding: 0px;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid ${({ theme }) => theme.textColor};
  border-radius: 20px;
  transition: transform 0.1s ease;
  position: relative;
  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    padding: 5px 10px;
  }
`;

const ToggleIcon = styled.div<{ $isDark: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.sizes.small.iconWidth};
  height: ${({ theme }) => theme.sizes.small.iconHeight};
  transition: color 0.3s ease;
  color: ${({ $isDark, theme }) =>
    $isDark ? theme.menuColor : theme.iconColor};
`;

const ToggleThemeButton: React.FC<{ onToggle?: () => void }> = ({
  onToggle,
}) => {
  const { toggleTheme, theme } = useTheme();
  const [isDarkMode, setIsDarkMode] = useState(theme === darkTheme);

  const handleClick = () => {
    setIsDarkMode(!isDarkMode);
    toggleTheme();
    if (onToggle) {
      onToggle();
    }
  };
  return (
    <StyledToggleThemeButton onClick={handleClick}>
      <Icon>
        <ToggleIcon $isDark={isDarkMode}>
          <FiSun />
        </ToggleIcon>
      </Icon>
      <Icon>
        <ToggleIcon $isDark={!isDarkMode}>
          <FaMoon />
        </ToggleIcon>
      </Icon>
    </StyledToggleThemeButton>
  );
};

export default ToggleThemeButton;
