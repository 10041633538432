import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease;
  width: ${({ theme }) => theme.sizes.small.containerWidth};
  height: ${({ theme }) => theme.sizes.small.containerHight};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.containerWidth};
    height: ${({ theme }) => theme.sizes.medium.containerHight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.containerWidth};
    height: ${({ theme }) => theme.sizes.large.containerHight};
  }
`;

export default Container;
