import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { MEDIA_BREAKPOINTS } from '@/constants';

interface AnimatedTitleContainerProps {
  $isAnimated: boolean;
}

const AnimatedTitleContainer = styled.div<AnimatedTitleContainerProps>`
  opacity: ${({ $isAnimated }) => ($isAnimated ? '1' : '0')};
  transform: ${({ $isAnimated }) =>
    $isAnimated ? 'translateY(0)' : 'translateY(20px)'};
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
`;

interface AnimatedTitleProps {
  fontFamily?: string;
}

const AnimatedTitle = styled.h1<AnimatedTitleProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: ${({ theme, fontFamily }) =>
    fontFamily
      ? theme.sizes.fonts[fontFamily]
      : theme.sizes.fonts.rubikExtraBold};
  margin: 5px;
  font-size: ${({ theme }) => theme.sizes.small.titleFontSize};
  color: ${({ theme }) => theme.titleColor};

  text-shadow:
    -1.4px -1.4px 1.4px ${({ theme }) => theme.shadowLightColor},
    1.4px -1.4px 1.4px ${({ theme }) => theme.shadowLightColor},
    -1.4px 1.4px 1.4px ${({ theme }) => theme.shadowLightColor},
    1.4px 1.4px 1.4px ${({ theme }) => theme.shadowLightColor};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    font-size: ${({ theme }) => theme.sizes.medium.titleFontSize};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    font-size: ${({ theme }) => theme.sizes.large.titleFontSize};
  }
`;

interface TitleProps {
  children: React.ReactNode;
  fontFamily?: string;
}

const Title: React.FC<TitleProps> = ({ children, fontFamily }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  useEffect(() => {
    setIsAnimated(inView);
  }, [inView]);

  return (
    <AnimatedTitleContainer $isAnimated={isAnimated} ref={ref}>
      <AnimatedTitle fontFamily={fontFamily}>{children}</AnimatedTitle>
    </AnimatedTitleContainer>
  );
};

export default Title;
