import React from 'react';
import styled, { keyframes } from 'styled-components';

// Styled components
const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #333;
  font-size: 20px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ActivityIndicator = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 123, 255, 0.5);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: ${rotate} 1s linear infinite;
  margin: 0 auto;
`;

const LoadingIndicator: React.FC = () => (
  <CenteredMessage>
    <ActivityIndicator />
  </CenteredMessage>
);

export default LoadingIndicator;
