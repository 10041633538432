import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  Suspense,
  lazy,
} from 'react';
import styled from 'styled-components';

import {
  HEADER_HEIGHT,
  SECTIONS,
  MEDIA_BREAKPOINTS,
  SEPARATOR_HEIGHT,
} from '@/constants';

import { ThemeProvider } from '@/theme/ThemeProvider';
import { MenuDesktop, MenuMobile, Section, Footer } from '@/components';
import { Separator } from '@/components/StyledComponents';

import './global.css';
const mediumScreenBreakpoint = parseInt(MEDIA_BREAKPOINTS.mediumScreen, 10);

const SectionsWrapper = styled.div`
  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    padding-top: ${HEADER_HEIGHT}px;
  }
`;

const SectionScreenOne = lazy(
  () => import('@/Sections/SectionScreenOne/SectionScreenOne'),
);
const SectionScreenTwo = lazy(
  () => import('@/Sections/SectionScreenTwo/SectionScreenTwo'),
);
const SectionScreenThree = lazy(
  () => import('@/Sections/SectionScreenThree/SectionScreenThree'),
);
const SectionScreenFour = lazy(
  () => import('@/Sections/SectionScreenFour/SectionScreenFour'),
);

const sectionsComponents = [
  <SectionScreenOne />,
  <SectionScreenTwo />,
  <SectionScreenThree />,
  <SectionScreenFour />,
];

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(SECTIONS[0]);
  const sectionRefs = useRef(new Map());

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = useCallback((sectionName: string) => {
    const sectionElement = sectionRefs.current.get(sectionName);
    if (sectionElement) {
      const sectionTop =
        sectionElement.getBoundingClientRect().top + window.pageYOffset;
      const isDesktop = window.innerWidth >= mediumScreenBreakpoint;
      const scrollPosition = isDesktop
        ? sectionTop - HEADER_HEIGHT - SEPARATOR_HEIGHT.largeScreen
        : sectionTop - SEPARATOR_HEIGHT.smallScreen;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      setSelectedSection(sectionName);
    }
  }, []);

  const sections = useMemo(
    () =>
      SECTIONS.map((sectionName, index) => (
        <React.Fragment key={sectionName}>
          {index === 0 ? <Separator /> : null}
          <Section
            ref={el => sectionRefs.current.set(sectionName, el)}
            sectionIndex={index}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {sectionsComponents[index]}
            </Suspense>
          </Section>
          <Separator $reverse={index % 2 === 0} />
        </React.Fragment>
      )),
    [],
  );

  return (
    <ThemeProvider>
      <MenuDesktop
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        scrollToSection={scrollToSection}
      />
      <MenuMobile
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        selectedSection={selectedSection}
        scrollToSection={scrollToSection}
      />
      <SectionsWrapper>{sections}</SectionsWrapper>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
