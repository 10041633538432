import React from 'react';
import styled from 'styled-components';
import { FOOTER_TEXT } from '@/constants'; // Adjust the path as needed

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.menuColor};
  color: ${({ theme }) => theme.descriptionColor};
  padding: 1px;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <p>{FOOTER_TEXT}</p>
    </StyledFooter>
  );
};

export default Footer;
