import { useContext, useMemo } from 'react';
import { ThemeContext } from '@/theme/ThemeProvider';
import { backgroundImages } from '@/constants/images';

export const useResponsiveBackgroundImage = (sectionIndex: number) => {
  const { mode, isDesktop } = useContext(ThemeContext);
  const backgroundImageUrl = useMemo(() => {
    const size = isDesktop ? 'desktop' : 'mobile';
    return backgroundImages[mode][size][sectionIndex];
  }, [isDesktop, mode, sectionIndex]);

  return backgroundImageUrl;
};
