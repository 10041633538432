import React from 'react';

const preventDefaultEvents = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const handlePrevent = (
      e:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.TouchEvent<HTMLDivElement>,
    ) => {
      e.preventDefault();
    };

    return (
      <div onContextMenu={handlePrevent} onTouchStart={handlePrevent}>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default preventDefaultEvents;
