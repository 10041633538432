import React from 'react';
import styled, { keyframes } from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';
import preventDefaultEvents from '../hoc/preventDefaultEvents';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const CircleImage = styled.img`
  border-radius: 50%;
  width: ${({ theme }) => theme.sizes.small.circleImage};
  height: ${({ theme }) => theme.sizes.small.circleImage};
  margin-top: 20px;
  transition:
    width 0.3s ease,
    height 0.3s ease;

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    width: ${({ theme }) => theme.sizes.small.circleImage};
    height: ${({ theme }) => theme.sizes.small.circleImage};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.small.circleImage};
    height: ${({ theme }) => theme.sizes.small.circleImage};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.small.circleImage};
    height: ${({ theme }) => theme.sizes.small.circleImage};
  }
`;

const SpinningText = styled.svg`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 105px);
  animation: ${spin} 20s linear infinite;

  text {
    text-shadow:
      -2px -2px 2px ${({ theme }) => theme.shadowLightColor},
      2px -2px 2px ${({ theme }) => theme.shadowLightColor},
      -2px 2px 2px ${({ theme }) => theme.shadowLightColor},
      2px 2px 2px ${({ theme }) => theme.shadowLightColor};

    fill: ${({ theme }) => theme.circleTextColor};
    font-family: ${({ theme }) => theme.sizes.fonts.rubikExtraBold};
    font-size: 13px;
  }
`;

interface CircleImageWithTextProps {
  src: string;
  alt?: string;
}

const CircleImageWithText: React.FC<CircleImageWithTextProps> = React.memo(
  ({ src, alt }) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <CircleImage src={src} alt={alt} loading="lazy" />
        <SpinningText width="210" height="210" viewBox="0 0 100 100">
          <path
            id="circlePath"
            fill="none"
            stroke="transparent"
            d="M 50, 50 m -42, 0 a 42,42 0 1,1 84,0 a 42,42 0 1,1 -84,0"
          />
          <text>
            <textPath xlinkHref="#circlePath" startOffset="0%">
              Music - Love - Dance - Vibe- Party- DJ -
            </textPath>
          </text>
        </SpinningText>
      </div>
    );
  },
);

export default preventDefaultEvents(CircleImageWithText);
