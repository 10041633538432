import React from 'react';
import styled from 'styled-components';
import preventDefaultEvents from '../hoc/preventDefaultEvents';
import { MEDIA_BREAKPOINTS } from '@/constants';

const ImageWrapper = styled.div`
  border-radius: 90px;
  overflow: hidden;
`;

interface CustomImageProps {
  $imageType: 'largeImage' | 'horizontalImage';
  $gradientAngle: string;
}

type StyledImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  CustomImageProps;

const StyledImage = styled.img<StyledImageProps>`
  border-radius: 40px;
  transition:
    width 0.3s ease,
    height 0.3s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(
    ${({ $gradientAngle }) => $gradientAngle},
    ${({ theme }) => theme.gradientColors[0]},
    ${({ theme }) => theme.gradientColors[1]}
  );
  border-image-slice: 1;

  width: ${({ theme, $imageType }) => theme.sizes.small[$imageType + 'Width']};
  height: ${({ theme, $imageType }) =>
    theme.sizes.small[$imageType + 'Height']};

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    width: ${({ theme, $imageType }) =>
      theme.sizes.medium[$imageType + 'Width']};
    height: ${({ theme, $imageType }) =>
      theme.sizes.medium[$imageType + 'Height']};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme, $imageType }) =>
      theme.sizes.large[$imageType + 'Width']};
    height: ${({ theme, $imageType }) =>
      theme.sizes.large[$imageType + 'Height']};
  }
`;

interface ImageProps {
  src: string;
  alt?: string;
  imageType: 'largeImage' | 'horizontalImage';
  gradientAngle: string;
}

const LargeImage: React.FC<ImageProps> = ({
  src,
  alt,
  imageType,
  gradientAngle = '240deg',
}) => {
  return (
    <ImageWrapper>
      <StyledImage
        src={src}
        alt={alt}
        $imageType={imageType}
        $gradientAngle={gradientAngle}
      />
    </ImageWrapper>
  );
};

export default preventDefaultEvents(LargeImage);
