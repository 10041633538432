import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { MEDIA_BREAKPOINTS } from '@/constants';

interface AnimatedDescriptionProps {
  $isAnimated: boolean;
  fontFamily?: string;
  $size: 'small' | 'large'; // Add size prop
}

const AnimatedDescription = styled.p<AnimatedDescriptionProps>`
  font-family: ${({ theme, fontFamily }) =>
    fontFamily
      ? theme.sizes.fonts[fontFamily]
      : theme.sizes.fonts.rubikSemiBoldItalic};
  margin: 0;
  font-size: ${({ theme, $size }) =>
    theme.sizes.small[
      $size === 'small'
        ? 'descriptionSmallFontSize'
        : 'descriptionLargeFontSize'
    ]};
  color: ${({ theme }) => theme.descriptionColor};
  text-shadow:
    -1px -2px 0 ${({ theme }) => theme.shadowDarkColor},
    1px -2px 0 ${({ theme }) => theme.shadowDarkColor},
    -1px 1px 0 ${({ theme }) => theme.shadowDarkColor},
    1px 1px 0 ${({ theme }) => theme.shadowDarkColor};
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  opacity: ${({ $isAnimated }) => ($isAnimated ? '1' : '0')};
  transform: ${({ $isAnimated }) =>
    $isAnimated ? 'translateX(0)' : 'translateX(-20px)'};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    font-size: ${({ theme, $size }) =>
      theme.sizes.medium[
        $size === 'small'
          ? 'descriptionSmallFontSize'
          : 'descriptionLargeFontSize'
      ]};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    font-size: ${({ theme, $size }) =>
      theme.sizes.large[
        $size === 'small'
          ? 'descriptionSmallFontSize'
          : 'descriptionLargeFontSize'
      ]};
  }
`;

interface DescriptionProps {
  children: React.ReactNode;
  fontFamily?: string;
  size: 'small' | 'large';
}
const Description: React.FC<DescriptionProps> = ({
  children,
  fontFamily,
  size,
}) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    setIsAnimated(inView);
  }, [inView]);

  return (
    <AnimatedDescription
      $isAnimated={isAnimated}
      ref={ref}
      fontFamily={fontFamily}
      $size={size}
    >
      {children}
    </AnimatedDescription>
  );
};

export default Description;
