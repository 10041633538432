// constants/images.ts
export const backgroundImages = {
  dark: {
    mobile: [
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileDarkSection1.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileDarkSection2.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileDarkSection3.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileDarkSection4.webp',
    ],
    desktop: [
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopDarkSection1.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopDarkSection2.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopDarkSection3.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopDarkSection4.webp',
    ],
  },
  light: {
    mobile: [
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileLightSection1.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileLightSection2.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileLightSection3.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/mobileLightSection4.webp',
    ],
    desktop: [
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopLightSection1.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopLightSection2.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopLightSection3.webp',
      'https://djeliormorbucket.s3.eu-central-1.amazonaws.com/backgroundImage/desktopLightSection4.webp',
    ],
  },
};
