import styled, { css, keyframes } from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';

const buttonTransitions = css`
  transition:
    width 0.3s ease,
    height 0.3s ease,
    font-size 0.3s ease,
    background-color 0.3s ease,
    transform 0.3s ease;
`;

const hoverAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

export const Button = styled.button`
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryColor};
  border: 2px solid ${({ theme }) => theme.menuColor};
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  ${buttonTransitions}

  &:hover {
    background-color: ${({ theme }) => theme.MenuItemSelectedColor};
    color: ${({ theme }) => theme.buttonBackgroundColor};
    animation: ${hoverAnimation} 0.5s ease forwards;
  }
`;
export const FormButton = styled(Button)`
  font-family: ${({ theme }) => theme.sizes.fonts.rubikSemiBoldItalic};
  background-color: ${({ theme }) => theme.buttonBackgroundColor};
  color: ${({ theme }) => theme.textColor};
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 5px;
  cursor: pointer;
  padding: ${({ theme }) => theme.sizes.small.formButtonPadding};
  box-shadow: 0px 4px 8px ${({ theme }) => theme.shadowLightColor};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    padding: ${({ theme }) => theme.sizes.medium.formButtonPadding};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    padding: ${({ theme }) => theme.sizes.large.formButtonPadding};
  }

  &:hover {
    background-color: ${({ theme }) => theme.MenuItemSelectedColor};
    color: ${({ theme }) => theme.buttonBackgroundColor};
    animation: ${hoverAnimation} 0.5s ease forwards;
  }
`;

export const MenuButton = styled(Button)<{ $isSelected?: boolean }>`
  display: flex;
  font-family: ${({ theme }) => theme.sizes.fonts.rubikBoldItalic};
  width: ${({ theme }) => theme.sizes.small.menuButtonWidth};
  height: ${({ theme }) => theme.sizes.small.menuButtonHeight};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.MenuItemSelectedColor : theme.menuColor};
  color: ${({ theme }) => theme.textColor};
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  ${buttonTransitions}

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
    transform: translateY(-2px);
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    font-family: ${({ theme }) => theme.sizes.fonts.rubikSemiBoldItalic};
    width: ${({ theme }) => theme.sizes.medium.menuButtonWidth};
    height: ${({ theme }) => theme.sizes.medium.menuButtonHeight};
    font-size: 18px;
    border-radius: 5px;
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.menuButtonWidth};
    height: ${({ theme }) => theme.sizes.large.menuButtonHeight};
    font-size: 24px;
  }
`;

const StyledComponents = { FormButton, MenuButton };

export default StyledComponents;
