export const HEADER_HEIGHT = 60;
export const SECTIONS = ['בית', 'גלריה', 'הסיפור שלי', 'דברו איתי'];

export const SEPARATOR_HEIGHT = {
  smallScreen: 5,
  mediumScreen: 5,
  largeScreen: 5,
};

export const MEDIA_BREAKPOINTS = {
  smallScreen: '576px',
  mediumScreen: '769px',
  largeScreen: '1024px',
};

export const albumsOrder = [
  'חתונות',
  'פורום',
  'כתבות',
  'חינות',
  'המלצות',
  'בר מצוות',
  'ברים',
  'פלאיירים',
];

//Section 1
export const SECTION_ONE_TITLE = 'מסע מוזיקלי בלתי נשכח';
export const SECTION_ONE_DESCRIPTION_LINES = [
  'ביחד ניצור את האירוע שתמיד חלמתם עליו',
  'עם אווירה חגיגית שמחה ומרגשת',
  'צלילים שמשאירים חיוך על הפנים',
  'רחבת ריקודים נמשכת בלי הפסקה',
  'צפו בווידאו ותחוו מהקסם',
];
export const SECTION_ONE_IMAGE_SRC = "https://djeliormorbucket.s3.eu-central-1.amazonaws.com/Section+1+Image/Elior_Mor59.jpg";

//Section 2
export const SECTION_TWO_TITLE = 'רגעים של אהבה ושמחה';

//Section 3
export const SECTION_THREE_TITLE = 'אווירה מוזיקלית מושלמת';
export const SECTION_THREE_DESCRIPTION =
  'אליאור מור - התקליטן שישדרג את האירוע שלך לחוויה מרהיבה ומרגשת במיוחד. עם יותר מ-15 שנות ניסיון באירועים, אני מביא איתי את המקצועיות הנדרשת ליצירת אירוע בלתי נשכח. אני לא רק מבין במוזיקה - אני נושם אותה, היא מדליקה אש בליבי שמאירה כל אירוע אליו אני מגיע. חתונות, חינות, בר מצוות, מועדונים ועד לאירועי קונספט מיוחדים - כל אחד מהם הופך לאירוע איכותי עם האנרגיה, הסט המוזיקלי המדויק, החיוך והרגעים הנפלאים שאני מבטיח לספק. אני מיומן בקריאת הקהל, הבנת האווירה והתאמת המוזיקה למגוון סגנונות בדיוק לטעמכם ולרצונכם, עם יותר מעשור של ניסיון כתקליטן במועדון הפורום ובתקלוט באירועים ופסטיבלים ברחבי הארץ. אז אם אתם מחפשים תקליטן שיהפוך כל רגע לחגיגה אמיתית שתשאיר את כל האורחים עם חיוך, אתם מוזמנים לבחור בי, אליאור מור, התקליטן שיהפוך כל אירוע לחוויה מוזיקלית מושלמת';

//Section 4
export const SECTION_FOUR_TITLE = 'שדרג את האירוע שלך';
export const SECTION_FOUR_DESCRIPTION = 'השאר פרטים ואחזור אליך';

export const FOOTER_TEXT = '©כל הזכויות שמורות לאליאור מור';
