import React from 'react';
import styled from 'styled-components';
import { RiWhatsappFill } from 'react-icons/ri';
import { PiInstagramLogoFill } from 'react-icons/pi';
import { MdOutlineFacebook } from 'react-icons/md';

import { Icon } from '../StyledComponents';

interface SocialMediaMenuProps {
  orientation: 'horizontal' | 'vertical';
}

const SocialMediaContainer = styled.div<SocialMediaMenuProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.orientation === 'horizontal' &&
    `
    flex-direction: row;
    > * {
      margin: 0 10px; // Horizontal spacing for all items
    }
  `}

  ${props =>
    props.orientation === 'vertical' &&
    `
    flex-direction: column;
    > * {
      margin: 10px 0; // Vertical spacing for all items
    }
    padding-bottom: 10px; // Adds padding to the bottom when vertical
  `}
`;

const SocialIconLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const SocialMediaMenu: React.FC<SocialMediaMenuProps> = ({ orientation }) => {
  return (
    <SocialMediaContainer orientation={orientation}>
      <SocialIconLink
        href="https://wa.me/0528623221"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon>
          <RiWhatsappFill />
        </Icon>
      </SocialIconLink>
      <SocialIconLink
        href="https://www.instagram.com/dj.elior_mor/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon>
          <PiInstagramLogoFill />
        </Icon>
      </SocialIconLink>
      <SocialIconLink
        href="https://www.facebook.com/DjEliorMor"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon>
          <MdOutlineFacebook />
        </Icon>
      </SocialIconLink>
    </SocialMediaContainer>
  );
};

export default SocialMediaMenu;
