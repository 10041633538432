import React from 'react';
import styled from 'styled-components';

import { SECTIONS, HEADER_HEIGHT } from '@/constants';

import { ToggleThemeButton, SocialMediaMenu, Logo } from '@/components';
import { MenuButton } from '@/components/StyledComponents';

const StickyHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  background-color: ${({ theme }) => theme.menuColor};
  color: ${({ theme }) => theme.menuColor};
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: background-color 0.3s ease;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled(Container)``;

const CenterContainer = styled(Container)`
  max-width: 300px;
`;

const RightContainer = styled(Container)``;

const SocialsContainer = styled.div`
  margin-left: 20px;
`;

const MediaQuery = styled.div`
  opacity: 1;
  transition: opacity 0.3s ease;

  @media (max-width: 768px) {
    display: none;
    opacity: 0;
  }
`;

interface MenuDesktopProps {
  selectedSection: string;
  setSelectedSection: (section: string) => void;
  scrollToSection: (sectionName: string) => void;
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({
  selectedSection,
  setSelectedSection,
  scrollToSection,
}) => {
  const handleSectionClick = (section: string) => {
    setSelectedSection(section);
    scrollToSection(section);
  };

  return (
    <MediaQuery>
      <StickyHeader>
        <LeftContainer>
          <ToggleThemeButton />
          <SocialsContainer>
            <SocialMediaMenu orientation="horizontal" />
          </SocialsContainer>
        </LeftContainer>
        <CenterContainer>
          <Logo />
        </CenterContainer>
        <RightContainer>
          {SECTIONS.slice()
            .reverse()
            .map(section => (
              <MenuButton
                key={section}
                $isSelected={selectedSection === section}
                onClick={() => handleSectionClick(section)}
              >
                {section}
              </MenuButton>
            ))}
        </RightContainer>
      </StickyHeader>
    </MediaQuery>
  );
};

export default MenuDesktop;
