import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { darkTheme, lightTheme, Theme } from './themes';
import { MEDIA_BREAKPOINTS } from '@/constants';

// Update ThemeContext to include screen size
export const ThemeContext = createContext<{
  theme: Theme;
  mode: 'dark' | 'light';
  isDesktop: boolean; // Add this for screen size
  toggleTheme: () => void;
}>({
  theme: darkTheme,
  mode: 'dark',
  isDesktop: false, // Default value
  toggleTheme: () => {},
});

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(darkTheme);
  const [mode, setMode] = useState<'dark' | 'light'>('dark');
  const isDesktop = useMediaQuery({ minWidth: MEDIA_BREAKPOINTS.mediumScreen }); // Use react-responsive here

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === darkTheme ? lightTheme : darkTheme));
    setMode(prevMode => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeContext.Provider value={{ theme, mode, isDesktop, toggleTheme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

// Adjust useTheme hook to include isDesktop
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
