import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import axios from 'axios';
import { MEDIA_BREAKPOINTS } from '@/constants';

// Make sure to import the merged FormButton here
import { FormButton } from '@/components/StyledComponents';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const FormInput = styled(Field)`
  margin: 5px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 50px;
  text-align: right;
  transition: all 0.3s ease;

  width: ${({ theme }) => theme.sizes.small.inputWidth};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    padding: 10px;
    width: ${({ theme }) => theme.sizes.medium.inputWidth};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    padding: 12px;
    width: ${({ theme }) => theme.sizes.large.inputWidth};
  }
`;

const FormErrorMessage = styled(ErrorMessage)`
  color: red;
`;

interface FormValues {
  name: string;
  phoneNumber: string;
}

const initialValues: FormValues = {
  name: '',
  phoneNumber: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('חובה למלא שם'),
  phoneNumber: Yup.string()
    .required('חובה למלא מספר נייד')
    .matches(/^[0-9]{10}$/, 'מספר הטלפון חייב להיות בעל 10 ספרות בדיוק'),
});

const handleSubmit = async (
  values: FormValues,
  actions: {
    setSubmitting: (isSubmitting: boolean) => void;
    resetForm: () => void;
  },
) => {
  try {
    await axios.post(`${apiBaseUrl}/api/send-email`, values);
    alert('Email sent successfully!');
    actions.resetForm();
  } catch (error) {
    alert('Error sending email');
  } finally {
    actions.setSubmitting(false);
  }
};
const ContactForm: React.FC = () => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <FormContainer>
        <Form>
          <div>
            <FormInput type="text" id="name" name="name" placeholder="שם מלא" />
            <FormErrorMessage name="name" component="div" />
          </div>
          <div>
            <FormInput
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="מספר נייד"
            />
            <FormErrorMessage name="phoneNumber" component="div" />
          </div>
          <FormButton type="submit" disabled={isSubmitting}>
            שלח
          </FormButton>
        </Form>
      </FormContainer>
    )}
  </Formik>
);

export default ContactForm;
