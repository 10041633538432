import ReactPlayer from 'react-player';
import styled from 'styled-components';
import preventDefaultEvents from '../hoc/preventDefaultEvents';

const VideoWrapper = styled.div`
  border-radius: 20px; /* Adjust the border radius value as per your preference */
  overflow: hidden;
`;

const VideoContainer = styled.div`
  border: 2px solid transparent;
  border-image: linear-gradient(
    160deg,
    ${({ theme }) => theme.gradientColors[0]},
    ${({ theme }) => theme.gradientColors[1]}
  );
  overflow: hidden;
  border-image-slice: 1; /* Ensures border image stretches to fill border area */

  .react-player {
    margin-bottom: -10px;
  }
`;

const VideoPlayer = () => {
  return (
    <VideoWrapper>
      <VideoContainer>
        <ReactPlayer
          url="https://djeliormorbucket.s3.eu-central-1.amazonaws.com/Videos/album1/1.mp4"
          controls
          width="100%"
          height="100%"
          className="react-player"
        />
      </VideoContainer>
    </VideoWrapper>
  );
};

export default preventDefaultEvents(VideoPlayer);
