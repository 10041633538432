import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';

interface SeparatorProps {
  $reverse?: boolean;
}

const Separator = styled.div<SeparatorProps>`
  width: 100%;
  height: 5px;
  background: linear-gradient(
    ${({ $reverse }) => ($reverse ? '-100deg' : '100deg')},
    ${({ theme }) => theme.gradientColors[0]},
    ${({ theme }) => theme.gradientColors[1]}
  );
  transition: height 0.3s ease;

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    height: 5px;
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    height: 5px;
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    height: 5px;
  }
`;

export default Separator;
