import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';

const VideoStyle = styled.div`
  transition:
    width 0.3s ease,
    height 0.3s ease; // Add this line for smooth transitions

  width: ${({ theme }) => theme.sizes.small.videoWidth};
  height: ${({ theme }) => theme.sizes.small.videoHeight};

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    width: ${({ theme }) => theme.sizes.small.videoWidth};
    height: ${({ theme }) => theme.sizes.small.videoHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.videoWidth};
    height: ${({ theme }) => theme.sizes.medium.videoHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.videoWidth};
    height: ${({ theme }) => theme.sizes.large.videoHeight};
  }
`;

export default VideoStyle;
