import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from '@/constants';

const Icon = styled.div`
  cursor: pointer;
  color: ${({ theme, color }) => color || theme.iconColor};
  justify-content: center;
  align-items: center;
  & > svg {
    transition:
      width 0.3s ease,
      height 0.3s ease;
  }

  @media (max-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    & > svg {
      margin-left: 5px;
      width: ${({ theme }) => theme.sizes.small.iconHeight};
      height: ${({ theme }) => theme.sizes.small.iconHeight};
      box-shadow:
        0 4px 6px ${({ theme }) => theme.shadowDarkColor},
        0 1px 3px ${({ theme }) => theme.shadowLightColor};
      border-radius: 50%; // Ensures a perfect circular shape
      background: linear-gradient(
        150deg,
        ${({ theme }) => theme.gradientColors[0]},
        ${({ theme }) => theme.gradientColors[1]}
      );
      color: ${({ theme }) => theme.iconColor};
    }
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    & > svg {
      margin-left: 5px;
      width: ${({ theme }) => theme.sizes.small.iconHeight};
      height: ${({ theme }) => theme.sizes.small.iconHeight};
      box-shadow:
        0 4px 6px ${({ theme }) => theme.shadowDarkColor},
        0 1px 3px ${({ theme }) => theme.shadowLightColor};
      border-radius: 50%; // Ensures a perfect circular shape
      background: linear-gradient(
        100deg,
        ${({ theme }) => theme.gradientColors[0]},
        ${({ theme }) => theme.gradientColors[1]}
      );
      color: ${({ theme }) => theme.iconColor};
    }
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    & > svg {
      width: ${({ theme }) => theme.sizes.medium.iconWidth};
      height: ${({ theme }) => theme.sizes.medium.iconHeight};
    }
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    & > svg {
      width: ${({ theme }) => theme.sizes.large.iconWidth};
      height: ${({ theme }) => theme.sizes.large.iconHeight};
    }
  }
`;

export default Icon;
