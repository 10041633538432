import React from 'react';
import styled from 'styled-components';

import { useTheme } from '@/theme/ThemeProvider';
import { lightTheme } from '@/theme/themes';
import { logodark, logolight } from '@/assets/images';
import { MEDIA_BREAKPOINTS } from '@/constants';
import preventDefaultEvents from '../hoc/preventDefaultEvents';

const LogoImage = styled.img`
  width: ${({ theme }) => theme.sizes.small.logoWidth};
  height: ${({ theme }) => theme.sizes.small.logoHeight};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.logoWidth};
    height: ${({ theme }) => theme.sizes.medium.logoHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.logoWidth};
    height: ${({ theme }) => theme.sizes.large.logoHeight};
  }
`;

const Logo: React.FC = () => {
  const { theme } = useTheme();

  const logoSrc = theme === lightTheme ? logodark : logolight;

  return <LogoImage src={logoSrc} alt={'דיג׳אי אליאור מור לוגו'} />;
};

export default preventDefaultEvents(Logo);
